.page-info{
    margin:0px;
}

// graf
.page-graf-block{
    display:flex;
    align-items: stretch;
    justify-content: center;
}
.page-graf{
    display:flex;
    align-items: stretch;
    white-space: nowrap;
    flex-direction: row-reverse;
    background: var(--color-green-light);
    border-radius:10px;
    margin:5px auto 15px;
    font-weight: 500;
    font-size:16px;
    div{
        border-radius:10px;
        display:flex;
        padding:15px 20px;
        align-items: center;
        justify-content: center;
        flex:1;
        &:first-child{
            background: var(--color-green);
        }
    }
}

.page-shifr{
    display:flex;
    align-items: center;
    justify-content: space-between;
    grid-gap:10px;
    &._avia{
        &:after{
            content: '';
            height:16px;
            width: 16px;
            background:url(/img/icons/plane.png) no-repeat 50% 50%;
            background-size:contain;
            display: block;
        }
    }
}

.page-status{
    font-size:12px;
    font-weight: 500;
    margin-top:5px;
}
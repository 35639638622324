.enter-form{
    display:flex;
    align-items: center;
    justify-content: center;
    flex:1;
    flex-direction: column;
}

.enter-form-block{
    background:#fff;
    box-shadow: 0 0 5px rgba(0,0,0,.2);
    border-radius:7px;
}

.enter-form-top{
    color:#fff;
    background:#000;
    padding:20px;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    border-radius:7px 7px 0px 0px;
}

.enter-form-fields{
    padding:20px 30px 30px;
    display:grid;
    grid-template-columns: 1fr;
    grid-gap:15px;
}

// install
.enter-logo{
    display:flex;
    align-items: center;
    height:60px;
    background: url(/img/logo.png) no-repeat 10px 50% #000;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    color:#fff;
    border-radius:7px;
    padding:0px 30px 0px 70px;
    background-size: 40px auto;
    margin-top:60px;
    line-height: 1;
    transition: 0.1s;
    cursor: pointer;
    &:hover{
        transform: scale(0.98);
    }
}

.enter-copy{
    font-weight: 400;
    font-size:14px;
    margin-top:20px;
}
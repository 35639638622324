.stat{
 display: flex;
 flex-wrap: wrap;
}

.stat-item{
    margin:0 15px 15px 0px;
    background:#f3fffc;
    border-radius:20px;
    padding: 10px 15px;
    font-size: 14px;
    box-shadow: 0 0 5px rgba(0,0,0,.2);
    font-weight: 500;
}
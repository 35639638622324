// title
.title-line {
    position: relative;
    height: 3px;
    background: var(--color-green);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;

    >span {
        color: var(--color-green);
        display: inline-block;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 20px;
        margin: -20px auto;
        position: absolute;
        padding: 0 30px;
        background: #fff;
    }
}

h3,
.title-small {
    font-size: 22px;
    line-height: 1.2;
    font-weight: 500 !important;

    @media(max-width:$pad) {
        font-size: 20px;
    }
}

h2,
.title-middle {
    font-size: 28px;
    line-height: 1.2;
    font-weight: 500 !important;

    @media(max-width:$pad) {
        font-size: 20px;
    }
}

h1,
.title {
    font-size: 24px;
    line-height: 1.2;
    font-weight: 500 !important;
    margin-bottom:15px;

    @media(max-width:$pad) {
        font-size: 26px;
    }
}
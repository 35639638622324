@import "../css/var.scss";

.menu{
    display:flex;
    justify-content: flex-start;
    height:45px;
}

.menu-block{
    margin:15px auto 0px;
    width: 100%;
    max-width: 840px;
    padding: 0 30px;
    @media(max-width:$pad){
        padding:0;
    }
}

.menu-item{
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color:#000;
    padding:0px 20px;
    display:flex;
    align-items: center;
    border-radius: 3px 3px 0 0;
    margin-right:2px;
    background: rgba(255, 255, 255, 0.5);
    &:hover{
        background: rgba(255, 255, 255, 0.9);
    }
    &.active{
        background: rgb(255, 255, 255);
    }
}
.icons{
    height:32px;
    width: 32px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 24px;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-size: auto 24px;
    transition: 0.3s;
    &._small{
        height:24px;
        width: 24px;
        background-size: auto 18px;
        -webkit-mask-size: auto 18px;
    }
    &._big{
        height:64px;
        width: 64px;
        background-size: auto 32px;
        -webkit-mask-size: auto 32px;
    }
    &:hover{
        transform: scale(0.9);
    }
}

.icons._exit{
    background-image: url(/img/icons/exit.svg);
}
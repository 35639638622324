.filter{
    display:flex;
    grid-gap:15px;
}

.filter-block{
    margin:15px -30px;
    padding:0px 30px;
}

.filter-item{
    border: 1px solid #C2F1EC;
    border-radius: 5px;
    padding:10px 15px;
    white-space: nowrap;
    cursor:pointer;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    &:hover{
        color:var(--color-blue);
    }
}

// set
.filter-set{
    display:flex;
    justify-content: space-between;
    grid-gap:10px;
    margin:15px 0px;
    >div:last-child{
        flex:1;
        display:flex;
        grid-gap:10px;
        flex-wrap: wrap;
    }
}

.filter-set-item{
    background: #f0f0f0;
    display:flex;
    align-items: center;
    border-radius: 5px;
    padding:5px 10px;
    grid-gap:10px;
    font-size:14px;
    font-weight:300;
    color:#777;
    >div div{
        display:inline-block;
        font-weight:500;
        color:#000;
        margin-left:10px;
    };
    span{
        display:block;
        height:16px;
        width: 16px;
        background: url(/img/close_black.svg) no-repeat 50% 50%;
        border-radius:5px;
        cursor: pointer;
    }
}

.filter-set-clear{
    background:url(/img/close_white.svg) no-repeat 50% 50% #000;
    background-size: 12px 12px;
    height:24px;
    width: 24px;
    border-radius:5px;
    cursor: pointer;
}

// filter book
.filter-form{
    display: flex;
    flex-direction: column;
    grid-gap:15px;
}

// filter client search
.filter-client{
    display:flex;
    grid-gap:10px;
    flex-direction: column;
}

.filter-client-res{
    flex-direction: column;
    display:flex;
    grid-gap:3px;
    >div{
        cursor:pointer;
        border-radius:3px;
        padding:14px;
        border:1px solid var(--color-green);
        &:hover{
            background:var(--color-green-light)
        }
    }
}
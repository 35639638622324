.table{
    display:table;
    position: relative;
    border-spacing: 0;
    th{
        top:0;
        position: sticky;
        background:#f3fffc;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 14px;
        &._sort{
            color:#16CDCA;
            cursor:pointer;
        }
    }
    td,th{
        padding:10px 5px;
        white-space: nowrap;
        text-align: left;
        &:first-child{
            padding-left:15px;
        }
        &:last-child{
            padding-right:15px;
            text-align: right;
        }
    }
    thead{
        top:0;
        position: sticky;
        th{
            padding:15px 5px;
            border-bottom:2px solid var(--color-green);
        }
    }
    tfoot{
        bottom:0;
        position: sticky;
    }
}
.table-row{
    cursor: pointer;
    &.active{
        background: var(--color-green-light);
    }
}
.table-total{
    td{
        font-weight: 500;
        padding-top:15px;
        border-top:2px solid var(--color-green);
        background:#f3fffc;
        text-transform:uppercase;
        font-size: 14px;
    }
}

.table-text-overflow{
    max-width: 0;
    width: 30%;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}

.table-flex{
    display:flex;
    justify-content: space-between;
}
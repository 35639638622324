.top{
    padding:20px 0px 0px;
}

.top-flex{
    display: grid;
    grid-gap:20px;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.top-lang{
    display: flex;
    gap:15px;
    .top-lang-item{
        cursor:pointer;
        color:#fff;
        font-size: 14px;
        font-weight: 500;
        &.active,&:hover{
            color:#000;
        }
    }
}
@import "css/var.scss";
@import "css/fonts.scss";
@import "css/icons.scss";
@import "css/inputs.scss";
@import "css/overflow.scss";
@import "css/block.scss";
@import "css/table.scss";
@import "css/title.scss";

body {
    margin: 0;
    font-family: 'Roboto';
    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    //     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    //     sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    background: linear-gradient(90deg, #15CDCA 4.27%, #4FE0B6 45.5%);
    &._animated{
        //background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background: linear-gradient(-45deg, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
        height: 100vh;
    }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

:root {
    --color-href: #14ADB1;

    --color-blue: #14ADB1;
    --color-blue-light: #39c5ca;

    --color-green: #4FE0B6;
    --color-green-light: #cffff1;

    --color-status-green: #E5F1C2;
    --color-status-blue: #C2F1EC;
    --color-status-yel: #F4DCB7;
    --color-status-grey: #EAEAEA;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
*{
    outline:none;border:0px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    //user-select:none;
}

:focus,
:active {
    outline: none;
}

a:focus,
a:active {
    outline: none;
}

nav,
footer,
header,
aside {
    display: block;
}

html,
body {
    height: 100%;
    width: 100%;
    font-size: 100%;
    line-height: 1;
    font-size: 16px;
    font-weight: 400;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

form{
    padding:0px;
    margin:0px;
    border:0px
}

input,
button,
textarea {
    font-family: inherit;
}

input::-ms-clear {
    display: none;
}

button {
    cursor: pointer;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a,
a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

// ul {
//     list-style: none;
//     padding-left:18px;
//     display:flex;
//     flex-direction: column;
//     grid-gap:1em;
//     margin:10px 0px;
// }

// ul li{
//     word-break: break-all;
// }

// ul li::before {
//     position:absolute;
//     content: "\2022";
//     color:rgba(37, 175, 248, 0.5);
//     font-weight: bold;
//     display: inline-block;
//     flex:0 0 20px;
//     margin-left: -1em;
//     font-size:20px;
//     height:1em;
//     display:flex;
//     align-items: center;
// }

img{
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

p {
    min-height: 1em;
    margin: 1em 0;
}

a,
.href {
    text-decoration: none;
    color: var(--color-href);
    cursor: pointer;
}

._center {
    text-align: center;
}

._left {
    text-align: left;
}

._right {
    text-align: right;
}

._width {
    width: 100% !important;
}

._inline {
    display: inline-block;
}

._margin {
    margin: 20px 0px;
}

._opacity {
    opacity: 0.5;
}

._half {
    width: 50%;
    margin: 0 auto;
    text-align: center;

    @media(max-width:$pad) {
        width: 100%;
    }
}

._black {
    color: var(--font-color);
}

.disabled {
    pointer-events: none;
}

._flex {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    align-items: center;
}

._relative {
    position: relative;
}

._inline{
    display:inline-block;
}
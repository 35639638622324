@keyframes ldio-bs873xp74oe {
    0% { transform: rotate(0) }
    100% { transform: rotate(360deg) }
  }
  .ldio-bs873xp74oe div { box-sizing: border-box!important }
  .ldio-bs873xp74oe > div {
    position: absolute;
    width: 84px;
    height: 84px;
    top: 8px;
    left: 8px;
    border-radius: 50%;
    border: 4px solid #000;
    border-color: #15CDCA transparent #15CDCA transparent;
    animation: ldio-bs873xp74oe 1.075268817204301s linear infinite;
  }
  .ldio-bs873xp74oe > div:nth-child(2) { border-color: transparent }
  .ldio-bs873xp74oe > div:nth-child(2) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }
  .ldio-bs873xp74oe > div:nth-child(2) div:before, .ldio-bs873xp74oe > div:nth-child(2) div:after { 
    content: "";
    display: block;
    position: absolute;
    width: 4px;
    height: 4px;
    top: -4px;
    left: 36px;
    background: #15CDCA;
    border-radius: 50%;
    box-shadow: 0 80px 0 0 #15CDCA;
  }
  .ldio-bs873xp74oe > div:nth-child(2) div:after { 
    left: -4px;
    top: 36px;
    box-shadow: 80px 0 0 0 #15CDCA;
  }
  .loadingio-spinner-dual-ring-x63m4hm89l {
    width: 91px;
    height: 91px;
    display: block;
    overflow: hidden;
    margin:0 auto;
  }
  .ldio-bs873xp74oe {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.91);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-bs873xp74oe div { box-sizing: content-box; }
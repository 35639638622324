.content{
    flex:1;
    background:#fff;
    display: flex;
}

.content-block{
    padding:20px 0px;
    height:100%
}

.content-table{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.content-info{
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.content-res{
    flex: 1;
    overscroll-behavior: none;
}

// fin-content
.fin-content{
    height:2000px;
}
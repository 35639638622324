.hotel-block{
    flex:1;
    position: relative;
    overflow: hidden;
    background:#caeeed;
    border:2px solid #16CDC9;
    @media(max-width:768px){
        margin:0 -20px -20px;
    }
}

.hotel-first{
    height:40px;
    width:60px;
    position:absolute;
    top:0; 
    left:0;
    z-index: 3;
    background:#20bbb7;
    color:#fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 30px;
}

.hotel-filter{
    display:flex;
    justify-content: space-between;
    margin-bottom:15px;
    align-items: flex-end;
}

.hotel-filter-left{
    display:flex;
    grid-gap:10px;
    align-items: center;
}

.hotel-scroll{
    overscroll-behavior: none;
    position: relative;
    height: 100%;
}

.hotel-top{
    top:0;
    right:0;
    margin-left:60px;
    position: sticky;
    position: -webkit-sticky;
    display:flex;
    z-index: 1;
    grid-gap:1px;
    background: #fff;
    >div{
        flex:0 0 60px;
        height:40px;
        background:#c2f1ec;
        font-size: 14px;
        font-weight: 500;
        position: relative;
        &._weekend::after{
            content: '';
            height: 100%;
            width: 100%;
            background: #16CDC9;
            z-index: 1;
            position: absolute;
            top:0;
            left:0;
        }
    }
    .hotel-data{
        height: 100%;
        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        z-index: 2;
    }
    .hotel-day{
        margin-top:3px;
        font-size: 10px;
    }
}

.hotel-left{
    width: 60px;
    display:flex;
    grid-gap:1px;
    flex-direction: column;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    top:0;
    left:0;
    background: #fff;
    .hotel-left-col{
        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding:10px;
        height:40px;
        width: 60px;
        background:#c2f1ec;
        font-size: 14px;
        font-weight: 500;
        &._close{
            background:#f7cadd;
        }
    }
    .hotel-type{
        font-weight: 500;
        font-size: 10px;
        margin-top:3px
    }
    .hotel-left-type{
        cursor:pointer;
        height:25px;
        font-weight: 600;
        color:#fff;
        background: #16CDC9;
        text-align: center;
        font-size: 16px;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .hotel-left-block{
        display: contents;
        &._hide{
            display:none;
        }
    }
}

.hotel-main{
    display:flex;
    grid-gap:1px;
    flex-direction: column;
    z-index: 0;
    position: absolute;
    top:0;
    left:0;
    margin-left: 60px;
    margin-top: 40px;
    overflow:hidden;
    .hotel-main-row{
        display:flex;
        grid-gap:1px;
        .hotel-main-cell{
            display: flex;
            align-items: center;
            flex:0 0 60px;
            height:40px;
            background:#fdfefe;
            position: relative;
            &._weekend:after{
                top:0;
                left:0;
                position: absolute;
                content: '';
                height: 100%;
                width: 100%;
                background: #c2f1ec;
                opacity: 0.5;
            }
        }
    }
    .hotel-main-type{
        height:25px;
        background:#fff;
        cursor:pointer;
    }
    .hotel-main-block{
        display:contents;
        &._hide{
            display:none;
        }
    }
}

// book
.hotel-book{
    z-index: 2;
    position: absolute;
    left: 0;
    margin-left: 30px;
    cursor: pointer;
    &:hover{
        box-shadow:0 0 5px rgba(0,0,0,.1);
    }
    &._reverse{
        transform: translateX(-100%);
    }
    &._before{
        margin-left:0;
    }
}

.hotel-book-info{
    font-size: 13px;

    height: 100%;
    width: 100%;
     
    position: relative;
    overflow: hidden;
    
    background: #c4ffd0cc;
    border-radius: 5px;
    border: 1px solid #50E0B6;

    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;

    &._temp{
        background: #c4c7ffcc;
        border: 1px solid #506ae0;
    }
    &._rezerv{
        background: #ffeac4cc;
        border: 1px solid #e0a650;
    }

    >div{
        z-index: 2;
        padding: 7px 3px;
    }
    
    ._after &:after{
        content: '';
        z-index: 100;
        background: #50E0B6;
        position: absolute;
        right: 0;
        width: 30px;
        height: 100%;
        z-index: 1;
    }
    ._before &:before{
        content: '';
        z-index: 100;
        background: #50E0B6;
        position: absolute;
        left: 0;
        width: 30px;
        height: 100%;
        z-index: 1;
    }
}

.hotel-book-price{
    line-height: 1;
    text-align: center;
    z-index: 1000;
    white-space: nowrap;
    &._minus{
        color: #a32c2c;
    }
}

.hotel-days{
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
}
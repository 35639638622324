// position
.modal-center {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
}

// all view
.modal-center-block {
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    opacity: 0;
    height: 100%;
    width: 100%;
    padding: 20px;
    @media(max-width:500px){
        &:has(.bron-det) {
            padding: 0;
        }
    }

    .modal-center.active & {
        opacity: 1;
    }

    &:before {
        content: "";
        z-index: -1;
        top: 0;
        left: 0;
        bottom: 0;
        position: fixed;
        background: rgba(0, 0, 0, 0.15);
        opacity: 0.7;
        right: 0;
    }
}

// inside
.modal-center-content {
    cursor: default;
    z-index: 1;
    background: #fff;
    padding: 35px;
    position: relative;
    max-height: 100%;
    max-width: 400px;
    width: 100%;
    transition: 0.3s;
    transform: translateY(30px);
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    .modal-center.active & {
        transform: translateY(0px);
    }

    &:has(.bron-det) {
        height: 100%;
        @media(max-width:500px){
            border-radius: 0px;
            max-width: unset;
        }
    }
}

// close button
.modal-center-close {
    cursor: pointer;
    background: url(/img/close.svg) no-repeat 50% 50%;
    filter: grayscale(1);
    background-size: 16px 16px;
    height: 40px;
    width: 40px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}
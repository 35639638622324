// block
.block {
    width: 100%;
    max-width: 1280px;
    padding: 0 20px;
    margin: 0 auto;
}

.block-middle {
    width: 100%;
    max-width: 1040px;
    padding: 0 20px;
    margin: 0 auto;
}

.block-small {
    width: 100%;
    max-width: 840px;
    padding: 0 10px;
    margin: 0 auto;
}
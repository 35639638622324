.bron-det{
    display:flex;
    margin:-40px;
    height: calc(100% + 80px);
    flex-direction: column;
}

.bron-det-top{
    padding:30px;
    position: relative;
    &:after{
        background:linear-gradient(0deg,transparent,#000);
        opacity:0.1;
        position: absolute;
        top:100%;
        height: 10px;
        left:0;
        content:'';
        width: 100%;
        z-index: 1;
    }
}

.bron-det-name{
    font-weight: 500;
    font-size: 18px;
    margin-bottom:15px;
}

.bron-det-status{
    display:flex;
    justify-content: space-between;
    align-items: center;
    grid-gap:10px;
}

.bron-det-content{
    padding:30px;
    overflow:auto;
    background:#F0F0F0;
    overflow: auto;
    flex:1;
    position: relative;
}

.bron-det-clock{
    padding-right:40px;
    position: relative;
    display: inline-flex;
    grid-gap:10px;
    font-weight: 500;
    align-items: center;
    color:var(--color-blue);
    &:after{
        content: '';
        height:16px;
        width: 16px;
        background: url(/img/icons/clock.png) no-repeat;
        background-size: 16px auto;
    }
}

.bron-det-block{
    display:grid;
    grid-template-columns: 1fr;
    grid-gap:10px;
}

.bron-det-info{
    padding:20px 20px 30px;
    background:#fff;
    border-radius:5px;
}

.bron-det-title{
    font-weight: 500;
    font-size:16px;
    margin-bottom:20px;
}

.bron-det-grid{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:10px 15px;
    margin:20px 0px;
    .bron-det-space {
        grid-column: 1 / span 2;
        height: 15px;
        // @media(max-width:500px){
        //     display:none;
        // }
    }
    // @media(max-width:500px){
    //     grid-template-columns: 1fr;
    //     div:nth-child(odd){
    //         font-size: 14px;
    //         color:var(--color-blue);
    //     }
    // }
}
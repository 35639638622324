/**/
input,
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 

textarea,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,

select,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    box-shadow: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    width: 100%;
    resize: none;
    position:relative;
    background: transparent;
}

::-webkit-input-placeholder {
    color:#555;
}

// button
.button{
    cursor:pointer;
    display: inline-block;
    white-space: nowrap;
    line-height: 1.1;
}

.button._black{
    background: #000;
    color:#fff;
    border-radius: 5px;
    padding: 15px 34px;
    text-transform: uppercase;
    font-weight: 600;
    font-size:16px;
    transition: 0.1s;
    &:hover{
        background: var(--color-green);
    }
}

// textarea
textarea,
.textarea{
    min-height: 160px;
    padding:10px;
}

// inputs
.inputs{
    height: 45px;
    background:#fff;;
    border:1px solid #ccc;
    padding:10px 16px;
    border-radius:3px;
    font-size: 16px;
    line-height: 1.1;
    width: auto;
    &:disabled{
        opacity:0.7;
    }
}

.inputs-error{
    color: #db222a;
    font-size: 12px;
    margin-top: 3px;
}

.inputs-lalel{
    font-size: 12px;
}
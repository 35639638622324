.not-found{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding:60px 0px;
    background: #f2f8f8;
}
.not-found-flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    color:var(--color-green);
    line-height: 1;
}

.not-found-404{
    font-size: 110px;
}

.not-found-text{
    font-weight: 600;
    >span{
        display:block;
        font-size: 40px;
    }
}
// roboto
@font-face{
    font-family:Roboto;
    font-weight:400;
    font-style:normal;
    src:url(fonts/roboto/Roboto-Regular.ttf);
    font-display: swap;
}

@font-face{
    font-family:Roboto;
    font-weight:500;
    font-style:normal;
    src:url(fonts/roboto/Roboto-Medium.ttf);
    font-display: swap;
}

@font-face{
    font-family:Roboto;
    font-weight:600;
    font-style:normal;
    src:url(fonts/roboto/Roboto-Bold.ttf);
    font-display: swap;
}
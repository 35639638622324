// overflow none
.overflow-none{
    overflow-x:hidden;
    overflow-y:auto;
}
.overflow-none::-webkit-scrollbar{
    display:none
}
.overflow-none::-webkit-scrollbar-thumb{
    display:none
}
.overflow-none::-webkit-scrollbar-track{
    display:none
}
.overflow-none::-webkit-scrollbar-button{
    display:none
}

// overflow
.overflow-x{
    overflow-x:hidden;
}

.overflow-y{
    overflow-y:hidden;
}


/* overflow*/
.overflow{
    overflow-x:auto;
    overflow-y:auto;
    padding:0;
    margin:0;
    min-height:0;
    min-width:0;
}

.overflow::-webkit-scrollbar{
    width: 6px;
    height: 6px;
}
.overflow::-webkit-scrollbar-thumb{
    display: block;
    border-radius: 7px;
    border-width: 4px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background: #0DB29D;
}
    
.overflow::-webkit-scrollbar-track{
    background: transparent;
}

.overflow::-webkit-scrollbar-button{
    display:none
}